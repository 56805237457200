import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

import useBrowserLanguage from "../assets/hooks/useBrowserLanguage"
import i18n from "../data/i18n"

const NotFoundPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          organization {
            contacts {
              email
            }
          }
        }
        buildTime
      }
      heroBg: file(relativePath: { eq: "hero-bg.jpg" }) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 700, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const email = data.site.siteMetadata.organization.contacts.email
  const heroImage = data.heroBg.childImageSharp

  const browserLang = useBrowserLanguage()

  return (
    <Layout lang={browserLang} location={location}>
      <SEO title={i18n[browserLang].page404.seoTitle} />

      {/* Hero */}
      <Hero
        uptitle={i18n[browserLang].page404.uptitle}
        title={i18n[browserLang].page404.title}
        image={heroImage}
      />

      <section className="grid grid-cols-12 gap-4 c-container lg:gap-8">
        <p
          className="col-span-10 md:col-span-8 lg:text-lg"
          dangerouslySetInnerHTML={{
            __html: i18n[browserLang].page404.content.replace("%email%", email),
          }}
        ></p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
