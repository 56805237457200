const useBrowserLanguage = () => {
  if (typeof navigator === "undefined") return "fr"

  const lang = navigator?.language?.split("-")[0]

  if (!lang) return "fr"

  switch (lang) {
    case "en":
      return "en"
    default:
      return "fr"
  }
}

export default useBrowserLanguage
